import { createRef, Component } from 'react';
// this is now included in bundle.config.js
import {autorun, toJS} from "mobx";
import {observer} from "mobx-react";
import {StoreContext} from "../../../stores/StoreLoader";

@observer
class Tags extends Component {
    static contextType = StoreContext;
    constructor(props) {
        super(props);

        this.state = {};
        this.component = createRef();
    }

    componentDidMount() {
        const {gridStore} = this.context;

        const Tagify = require('@yaireo/tagify');
        this.tagify = new Tagify(this.component.current, this.props.settings || {});
        const textCategorySearch = this.props.textCategorySearch;

        this.disabler = autorun(() => {
            if (gridStore.searchCategories?.length > 0) {
                this.tagify.settings.whitelist = toJS(gridStore.searchCategories).map(c => {
                    return {
                        alias: c.alias,
                        id: c.path.split(".")[c.path.split('.').length - 1],
                        value: c.category_name
                    }
                });
                this.tagify.dropdown.show.call(this.tagify);
            }

            // textCategorySearch refers to being able to input a category as text and filter by any matching categories
            if (!textCategorySearch) {
                // we shouldn't have to use this when searching for categories because all tagging should be handled by tagify
                this.tagify.removeAllTags();

                if (!gridStore.categoryFilters.allTopFiltersActive) {
                    const values = Array.from(gridStore.categoryFilters.filters.values())
                        .filter(category => category.active)
                        .map(category => {
                            return {
                                id: category.id, // this is necessary to distinguish categories from text tags
                                value: category.category_name
                            }
                        })
                    this.tagify.addTags(values, true);
                }
            }
        });
    }

    componentWillUnmount() {
        this.disabler();
    }

    onTabClosed = ({detail: component}) => {
        this.props.onTabClosed && this.props.onTabClosed(component.getAttribute('tabId'));
    };

    render() {
        const attrs = {
            ref: this.component,
            name: this.props.name,
            className: this.props.className,
            placeholder: this.props.placeholder,
            autoFocus: this.props.autofocus
        };

        if (this.props.mode === 'textarea') {
            return <textarea {...attrs} defaultValue={this.props.initialValue}/>;
        } else {
            return <input {...attrs} defaultValue={this.props.initialValue}/>;
        }

    }
}

export default Tags