import { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles/TooltipMenu.module.scss';

import classNames from 'classnames';

export default class TooltipMenu extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                action: PropTypes.func,
            })
        ),
        closeTimeout: PropTypes.number,
    };

    static defaultProps = {
        closeTimeout: 300,
    };

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        this.closeTimer = null;
    }

    showMenu = () => {
        this.setState({visible: true});
    };

    hideMenu = () => {
        const { closeTimeout } = this.props;

        clearTimeout(this.closeTimer);
        this.closeTimer = setTimeout(() => {
            this.setState({visible: false});
        }, closeTimeout);
    };

    render() {
        const { title, links } = this.props;

        const tooltipMenuClassNames = classNames({
            [styles.tooltipMenu]: true,
            [styles.bottomSide]: true,
            [styles.visible]: this.state.visible,
        });

        const tooltipMenuCloseClassNames = classNames({
            [styles.close]: true,
        });

        return (
            <div className={tooltipMenuClassNames}>
                <button className={tooltipMenuCloseClassNames} onClick={this.hideMenu} aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <h3>{title}</h3>
                <ul>
                    {
                        links.map((link) => {
                            return <li key={link.name}><button onClick={link.action}>{ link.name }</button></li>;
                        })
                    }
                </ul>
            </div>
        )

    }

}
